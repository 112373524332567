.metric-title {
  padding: 14px 0;
  margin: 0;
  display: block;
}

.metric-value {
  margin-top: 2px;
  font-size: 27px;
  padding-bottom: 18px;
  margin-bottom: 30px;
}

.unit {
  font-size: 18px;
}

.widgets {
  border: 1px solid #e2e5eb;
  border-radius: 4px;
  padding: 12px;
}

.widget-title {
  font-size: 16px;
  color: #8494a4;
  font-weight: 500;
}
