a {
  color: #000000cc !important;
  cursor: pointer;
}

.page {
  background-color: #f2f2f2;
  height: 100vh;
  position: absolute;
  z-index: 2;
  width: 100%;
}

.auth-card {
  border: 1px solid #e2e5eb;
  padding: 24px !important;
  border-radius: 8px;
  background-color: #ffffff;
  width: 408px;
}

.label {
  color: #8494a4;
  font-size: 14px;
  font-weight: bolder;
}

.error {
  color: #cb0000cc;
  font-style: italic;
  font-size: 12px;
}

.btn-disabled {
  background-color: #d8d9db !important;
  color: #a8a8a8 !important;
}

.info {
  color: #000000cc;
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 14px;
  margin-bottom: 24px;
}

.info-elements {
  font-size: 12px;
}

.login-redirect {
  padding-top: 16px;
  font-size: 12px;
  align-self: center;
}

.msg-success {
  color: #00af07;
  font-size: 14px !important;
  padding-bottom: 3px;
  margin: 0;
}

.hints {
  font-size: 12px;
  display: block;
}

.activate-account {
  height: 100vh;
}
