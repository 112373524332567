.text-danger {
  font-size: 12px;
}

.text-selected {
  color: #0750e2 !important;
}

.btn-disabled {
  background-color: #d8d9db !important;
  color: #a8a8a8 !important;
}

.btn-cancel:hover {
  background-color: #e8f1ff !important;
}
