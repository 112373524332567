.operation-table {
  margin: 0;
  color: #273b4e;
}

.crumb {
  transform: translateX(-12px) translateY(-8px);
}

.caret-right {
  transform: translateY(1px);
}

.kebab-options {
  z-index: 2;
  cursor: pointer;
}

.search-filter {
  min-width: 250px !important;
}

input:focus {
  outline: none;
}

#label-text{
  font-family: 'Roboto';
}







.tab-section-border {
  border-bottom: 1px solid #0750e2;
}

.tabs {
  max-width: 25%;
}

.tabfalse:hover {
  color: #0750e2;
}

.tab-active {
  color: #0750e2;
}

.outline-active {
  border: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top: 3px solid #0750e2;
}

.selector-menu {
  max-height: 50vh;
  overflow-y: scroll;
  border: 1px solid #e2e5eb;
  align-items: flex-start;
  width: 150px;
  position: absolute;
  z-index: 2;
  border-radius: 4px;
  background-color: white !important;
  margin-top: 4px;
}

.domain-filter {
  width: 95%;
}

.card {
  border-radius: 4px !important;
  border: 1px solid #e2e5eb !important;
  padding: 12px !important;
}

.reports {
  border-top: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.reportsHead { 
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #e2e5eb;
  padding: 5px;
}


.size-responsive {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.p-0.pt-responsive {
  flex-grow: 1; /* Allow the form to grow and take available space */
  min-width: 300px; /* Set a minimum width for the form */
}

/* .preview-pane-sticky {
  position: sticky;
  top: 0; 
  width: 30%; 
  height: calc(100vh - 20px);
  overflow-y: scroll; 
  max-height: calc(100vh - 20px); 
} */

.input-box, #inputbox {
  width: 100%; /* Make the input stretch to fill its container */
  padding: .8vh .8vw !important; /* Ensure consistent padding */
}

.selector-menu {
  max-height: 50vh; /* Prevent dropdown from expanding too much */
  overflow-y: scroll; /* Enable scrolling in the dropdown */
}

.output-view {
  height: 87vh;
}

.output-view::-webkit-scrollbar {
  width: 2px !important;
  background-color: white;
}

.output-view::-webkit-scrollbar-thumb {
  background-color: grey;
}

textarea::-webkit-scrollbar {
  display: none;
}
  
.customer-suggestions {
  z-index: 5;
}

.customer-suggestions > span:hover {
  background-color: #e2e5eb;
  cursor: pointer;
}

.preview-container {
  height: 25vh;
  overflow-y: scroll;
}

.preview-container {
  max-height: 100%; /* Set a max height */
  overflow-y: auto; /* Enable scrolling if content overflows */
}

.preview-container::-webkit-scrollbar {
  width: 2px !important;
  background-color: white;
}

.preview-container::-webkit-scrollbar-thumb {
  background-color: #0750e2;
}

/* .pc-lg {
  height: 80%;
} */

.pc-md {
  height: 70%;
}

.pc-sm {
  height: 60%;
}

.pt-responsive {
  padding-top: 1vh !important;
}

.size-responsive button,
.size-responsive input, 
.size-responsive textarea,
.size-responsive textarea::placeholder{
  font-size: 14px !important; 
  color: #8494a4;
}
.size-responsive .label-text, 
.size-responsive .label-text .size{
  font-size: 14px !important;
}
.size-responsive #dropdown-basic,
.size-responsive .radio-text,
.size-responsive .radio-text .text {
  color:#8494a4;
}
.size-responsive .filename-display,
.size-responsive .customer-suggestions *,
.preview-pane-item {
  font-size: 1.5vh !important ;
  margin-top: 0 !important;
  color: #8494a4;
}

.size-responsive .menu span {
  font-size: 14px !important;
}

.size-responsive .label-text {
  padding-bottom: .8vh !important;
  color: #8494a4;
}

.size-responsive button,
.size-responsive .filename-display {
  padding: .8vh .8vw !important;
}

.size-responsive .customer-name {
  padding: .1vh 12px !important;
  height: 3.8vh !important;
}

.mb-responsive {
  margin-bottom: 1.5vh !important;
}

.dummy-btn { 
  transition: all 1s ease;
}

.mini-label {
  position: absolute;
  background-color: white;
  font-size: 11px;
  padding: 0 6px;
  color: #8494a4;
  transform: translateY(-7px) translateX(7px);
  animation: fly .2s linear forwards;
}

.dropdown-mini-label {
  position: absolute;
  background-color: white;
  font-size: 11px;
  padding: 0 6px;
  color: #8494a4;
  transform: translateY(-18px) translateX(-6px);
}

.menu-option {
  color: #8494a4;
}

.menu-option:hover {
  color: #8494a4;
  background-color: #e9ecef!important;
  border-radius: 4px;
}

.dropdown-menu {
  max-height: 30vh;
  overflow-y: scroll;
}

#inputbox {
  font-size: 14px !important;
  margin-bottom: 1.5vh !important;
  line-height: 1.5;
  padding: .8vh .8vw !important;
  width: 100% !important;
  align-items: center !important;
  display: flex !important;
  font-weight: 400 !important; 
  color: #495057!important;
  font-family: 'Roboto', sans-serif; 
}

#inputbox::placeholder {
  font-family: 'Roboto', sans-serif; 
  font-size: 14px;
  color: #8494a4;  
}


.input-box {
  padding: 7px 12px;
}
.input-box::placeholder {
  font-family: 'Roboto', sans-serif; 
  font-size: 14px;
  color: #8494a4; 
}

.radio-container {
  display: flex;
  justify-content:start;
  width: 100%; 
  gap: 100px;
}

.radio-item {
  display: flex;
  align-items: center; 
}

.radio-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ced4da;
  box-shadow: 3px 3px 5px #cdcdcd;
}

.radio-button.filled {
  background-color: white;
  border: 1px solid #c1bebe;
}

.cw-0 {
  width: 6em !important;
}

.cw-1 {
  width: 115px !important;
}

.cw-2 {
  width: 140px !important;
}

.cw-3 {
  width: 185px !important;
}

.preview-pane-sticky {
  position: sticky;
  top: 0; 
  height: calc(100vh - 20px); 
  overflow-y: auto; 
}


/* inside text-box color: #495057 */


.snackbar-container {
  position: fixed;
  bottom: 20px; /* Position it at the bottom */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  z-index: 1000; /* Make sure it's on top */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Full width */
}

.snackbar {
  background-color: #333; /* Background color */
  color: #fff; /* Text color */
  padding: 16px; /* Padding */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Shadow */
  transition: opacity 0.5s ease; /* Smooth fade out */
  opacity: 0.9; /* Slight transparency */
  margin: 0 10px; /* Margin between multiple snackbars */
}

.checkbox-container {
  width: 1.3em;
  height: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #dee2e6;
  border-radius: 3px !important;
}

#text-label{
  font-family: 'Roboto';
  color: #8494a4 !important;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent;
  border: 0;
}

.Price{
  margin-bottom: 1.8%;;
}

.push{
  margin-right: 36.7%;
}

.form-check {
  position:relative;
  display: block;
  padding: auto;
}

.form-control::placeholder {
  font-family: 'Roboto', sans-serif; 
  font-size: 14px;
  color: #8494a4;
}

.form-control{
  font-family: 'Roboto', sans-serif; 
  font-size: 14px;
  color: #8494a4 !important;
}

#form-equativ{
  font-size: 14px !important; 
  color: #8494a4 !important;
  padding-bottom: 1.5vh !important;
}

#checkbox{
  padding-bottom: 1.5vh !important;
}

.inside-dd{
  color: #8494a4 !important;
}