.message-queue {
  position: fixed;
  bottom: 0;
  width: 40%;
}

.snackbar-green {
  border-left: 4px solid #2ad130 !important;
  background-color: #edffee !important;
}

.snackbar-red {
  border-left: 4px solid #d1302a !important;
  background-color: #ffeeed !important;
}

.snackbar-blue {
  border-left: 4px solid #2a57d1 !important;
  background-color: #edf3ff !important;
}

.cross {
  font-size: 16px;
  margin-right: 12px;
  line-height: 0.5;
  cursor: pointer;
}

.checkbox {
  width: 15px;
  height: 15px;
  border: 1px solid #a8a8a8;
  border-radius: 2px;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
}

.check-icon {
  position: absolute;
  transform: translateX(-1px) translateY(-1px);
  border-radius: 2px;
}

.radio-item {
  cursor: pointer;
}

.radio-button {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #ced4da;
}

.radio-button.filled {
  background-color: white;
  border: 2px solid #4285f3;
}

.radio-fill {
  background-color: #4285f3;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.helper {
  padding: 1px 2px 0px 2px;
  line-height: 0.9;
  border-radius: 50%;
  font-size: 11px !important;
  cursor: help;
}

.helper-white {
  border: 1px solid white;
  color: white;
}

.helper-gray {
  border: 1px solid #8494a4;
  color: #8494a4;
}

.helper-text {
  border: 1px solid #8494a4;
  color: #8494a4;
  position: absolute;
  line-height: 1.2;
  background-color: white;
  border-radius: 4px;
  padding: 8px;
  font-size: 12px !important;
  transform: translateY(-130%);
  z-index: 2;
  min-width: 240px;
}

.date-selector {
  width: 160px;
}

.filename-display {
  animation: animate .5s linear forwards;
  width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@keyframes animate {
  1% {
    width: 1px;
  }
  25% {
    width: 100px;
  }
  50% {
    width: 200px;
  }
  75% {
    width: 300px;
  }
  99% {
    width: 399px;
  }
}
