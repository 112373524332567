h2 {
  font-family: "Roboto-bold";
  font-weight: 800 !important;
  font-size: 24px !important;
  color: #273b4e;
  padding: 24px;
  margin-top: 16px !important;
}

a {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.796) !important;
}

span {
  font-size: 14px;
}

p {
  font-size: 16px !important;
}

button {
  font-size: 14px !important;
}

input {
  font-size: 14px !important;
}

textarea {
  resize: none !important;
}

strong {
  line-height: 1px;
}

label {
  margin: 0 !important;
  padding-bottom: 14px !important;
  font-family: "Roboto-bold";
  line-height: 1;
}

.bg--white {
  background-color: #ffffff !important;
}

.bg--light {
  background-color: #fafbfc !important;
}

.bg--secondary {
  background-color: #f1f2f4 !important;
}

.bg--primary {
  background-color: #4285f3 !important;
}

.border--light {
  border: 1px solid #e2e5eb !important;
}

.text--secondary {
  color: #8494a4 !important;
}

.text--primary {
  color: #0750e2 !important;
}

.text--body {
  color: #000000cc !important;
}

.text--white {
  color: white !important;
}

.text--red {
  color: #e52323 !important;
}

.text--green {
  color: #01a214 !important;
}

.text--blue {
  color: #002ab3 !important;
}

.form-group {
  margin: 0 !important;
  padding-bottom: 24px !important;
}

.image-overlay-text::selection {
  background-color: transparent !important;
  color: transparent !important;
}

.pointer {
  cursor: pointer;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}